import React from 'react';
import { graphql, Link } from 'gatsby';
import { Col, Container, Row } from 'reactstrap';
import { Helmet, useTranslation } from 'gatsby-plugin-react-i18next';
import CountUp from 'react-countup';
import { useLocation } from '@reach/router';
import { Section } from '../helpers';
import {
  Accordion,
  PrimaryButton,
  SecondaryButton,
  Layout,
  ForYourSuccess,
  Map,
  ContactForm,
} from '../components';
import AboutImage from '../../static/images/about.png';
import AchievementsImage from '../../static/images/achievements.png';
import KtuLogo from '../../static/logos/ktu.svg';
import VduLogo from '../../static/logos/vdu.svg';
import KlaipedosUnivesitetasLogo from '../../static/logos/klaipedos-universitetas.svg';
// import RespublikineKaunoLigonineLogo from '../../static/logos/respublikine-kauno-ligonine.png';
import ProjektanaLogo from '../../static/logos/projektana.png';
import KuranaLogo from '../../static/logos/kurana.svg';
import IdentalLogo from '../../static/logos/idental.svg';
import EuroChemaLogo from '../../static/logos/eurochema.svg';
import KaunoKolegijaLogo from '../../static/logos/kauno-kolegija.svg';
// import GiratesGinkluotesGamyklaLogo from '../../static/logos/girates-ginkluote-gamykla.png';
// import TeledemaLogo from '../../static/logos/teledema.svg';
import RespublikineSiauliuLigonineLogo from '../../static/logos/respublikine-siauliu-ligonine.svg';
import LonasLogo from '../../static/logos/lonas.svg';
import BestInLithuaniaBadge from '../../static/images/best-in-lithuania.png';
import TopCompanyBadge from '../../static/images/top-company.png';
import '../styles/app.scss';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const IntroSection: Section = {
    name: t('intro.title'),
    id: 'intro',
    isShownInNav: false,
    content: (
      <>
        <Container>
          <Row>
            <div className={'content'}>
              <div className={'left'}>
                <ForYourSuccess />
                <p className={'description'}>{t('intro.description')}</p>
                <div className={'buttons'}>
                  <Link to={'#contacts'}>
                    <PrimaryButton>{t('intro.contact')}</PrimaryButton>
                  </Link>
                  <Link to={'#services'}>
                    <SecondaryButton>{t('intro.services')}</SecondaryButton>
                  </Link>
                </div>
              </div>
              <div className={'right'}>
                <img src={TopCompanyBadge} alt={t('intro.top-company')} />
                <img
                  src={BestInLithuaniaBadge}
                  alt={t('intro.best-in-lithuania')}
                />
              </div>
            </div>
          </Row>
        </Container>
      </>
    ),
  };

  const aboutSectionCol = {
    md: 4,
  };

  const AboutSection: Section = {
    name: t('about.title'),
    id: 'about',
    isShownInNav: true,
    content: (
      <>
        <Container>
          <Row>
            <div className={'underline'}>
              <h1>{t('about.title')}</h1>
            </div>
          </Row>
          <Row className={'items'}>
            <Col className={'item'} {...aboutSectionCol}>
              <i className={'icon icon-work-principles'} />
              <h2>{t('about.items.0.title')}</h2>
              <div className={'description'}>
                {t('about.items.0.description')}
              </div>
            </Col>
            <Col className={'item'} {...aboutSectionCol}>
              <i className={'icon icon-experience'} />
              <h2>{t('about.items.1.title')}</h2>
              <div className={'description'}>
                {t('about.items.1.description')}
              </div>
            </Col>
            <Col className={'item'} {...aboutSectionCol}>
              <i className={'icon icon-consultations'} />
              <h2>{t('about.items.2.title')}</h2>
              <div className={'description'}>
                {t('about.items.2.description')}
              </div>
            </Col>
          </Row>
        </Container>
        <div className={'numbers'}>
          <div className={'years'}>
            <div className={'content'}>
              <CountUp
                start={0}
                end={25}
                delay={0}
                suffix={'+'}
                className={'number'}
              />
              <span className={'description'}>
                {t('about.years-description')}
              </span>
            </div>
          </div>
          <div className={'zoom'}>
            <img src={AboutImage} alt={t('about.title')} />
          </div>
          <div className={'clients'}>
            <div className={'content'}>
              <CountUp
                start={0}
                end={300}
                delay={0}
                suffix={'+'}
                className={'number'}
              />
              <span className={'description'}>
                {t('about.clients-description')}
              </span>
            </div>
          </div>
        </div>
      </>
    ),
  };

  const achievements: string[] = t('achievements.items', {
    returnObjects: true,
  });

  const AchievementsSection: Section = {
    name: t('achievements.title'),
    id: 'achievements',
    isShownInNav: true,
    content: (
      <>
        <Container>
          <Row>
            <div className={'underline'}>
              <h1>{t('achievements.title')}</h1>
            </div>
          </Row>
          <Row>
            <Col
              md={{
                offset: 6,
                size: 6,
              }}
              className={'wrapper'}
            >
              <div className={'content'}>
                <ul>
                  {achievements.map((item: string, index: number) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              </div>
              <div className={'background'} />
            </Col>
          </Row>
        </Container>
        <div className={'zoom'}>
          <img src={AchievementsImage} alt={t('achievements.title')} />
        </div>
      </>
    ),
  };

  const services: { title: string; items: string[]; id: string }[] = t(
    'services.items',
    { returnObjects: true },
  );

  const ServicesSection: Section = {
    name: t('services.title'),
    id: 'services',
    isShownInNav: true,
    content: (
      <>
        <Container>
          <Row>
            <div className={'underline'}>
              <h1>{t('services.title')}</h1>
            </div>
          </Row>
          <Row>
            <Col
              md={{
                offset: 5,
                size: 7,
              }}
              className={'items'}
            >
              {services.map((item: any, index: number) => {
                return (
                  <Accordion
                    title={item.title}
                    icon={item.icon}
                    key={`accordion-${index}`}
                  >
                    <ul>
                      {item.items.map((subService: string) => (
                        <li key={`subService-${subService}`}>{subService}</li>
                      ))}
                    </ul>
                  </Accordion>
                );
              })}
            </Col>
          </Row>
        </Container>
      </>
    ),
  };

  interface ClientLogo {
    title: string;
    path: string;
    maxWidth?: string | number;
  }

  const clientLogoList: ClientLogo[] = [
    {
      title: 'Kauno technologijos universitetas',
      path: KtuLogo,
    },
    {
      title: 'Vytauto Didžiojo universitetas',
      path: VduLogo,
    },
    {
      title: 'Klaipėdos universitetas',
      path: KlaipedosUnivesitetasLogo,
    },
    // {
    //   title: 'Republikinė Kauno ligoninė',
    //   path: RespublikineKaunoLigonineLogo,
    // },
    {
      title: 'Projektana',
      path: ProjektanaLogo,
    },
    {
      title: 'Kurana',
      path: KuranaLogo,
    },
    {
      title: 'i-dental',
      path: IdentalLogo,
      maxWidth: '9rem',
    },
    {
      title: 'EuroChema Lifosa',
      path: EuroChemaLogo,
    },
    {
      title: 'Kauno kolegija',
      path: KaunoKolegijaLogo,
    },
    // {
    //   title: 'Girates ginkluotės gamykla',
    //   path: GiratesGinkluotesGamyklaLogo,
    // },
    // {
    //   title: 'Teledema',
    //   path: TeledemaLogo,
    // },
    {
      title: 'Republikinė Šiaulių ligoninė',
      path: RespublikineSiauliuLigonineLogo,
    },
    {
      title: 'Lonas',
      path: LonasLogo,
      maxWidth: '9rem',
    },
  ];

  const ClientsSection: Section = {
    name: t('clients.title'),
    id: 'clients',
    isShownInNav: true,
    content: (
      <>
        <Container>
          <Row>
            <div className={'underline'}>
              <h1>{t('clients.title')}</h1>
            </div>
            <div className={'client-list'}>
              {clientLogoList.map((clientLogo, index) => (
                <img
                  style={
                    clientLogo.maxWidth ? { maxWidth: clientLogo.maxWidth } : {}
                  }
                  key={`client-logo-${index}`}
                  src={clientLogo.path}
                  alt={clientLogo.title}
                />
              ))}
              <div />
              <div />
            </div>
          </Row>
          <Row>
            <div>{t('clients.other')}</div>
          </Row>
        </Container>
      </>
    ),
  };

  const ContactsSection: Section = {
    name: t('contacts.title'),
    id: 'contacts',
    isShownInNav: true,
    content: (
      <>
        <div className={'background'}>
          <div className={'contact-form'} />
          <Map className={'map'} />
        </div>
        <Container>
          <Row>
            <div className={'underline'}>
              <h1>{t('contacts.title')}</h1>
            </div>
            <Col md={4}>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </>
    ),
  };

  const sections: Section[] = [
    IntroSection,
    AboutSection,
    AchievementsSection,
    ServicesSection,
    ClientsSection,
    ContactsSection,
  ];

  return (
    <Layout sections={sections}>
      <Helmet>
        <meta charSet={'utf-8'} />
        <title>{`${t('name')} - ${t('slogan')}`}</title>
        <meta name={'description'} content={t('description')} />
        <meta name={'keywords'} content={t('keywords')} />
        <meta
          name={'viewport'}
          content={'width=device-width, initial-scale=1.0'}
        />
        <meta property={'og:title'} content={`${t('name')} - ${t('slogan')}`} />
        <meta property={'og:description'} content={t('description')} />
        <meta property={'og:type'} content={'website'} />
        <meta property={'og:url'} content={location.origin} />
        <meta property={'og:image'} content={'/image.png'} />
        <link rel={'icon'} type={'image/svg+xml'} href={'favicon.svg'} />
      </Helmet>
      {sections.map((section: Section) => (
        <section id={section.id} key={section.id}>
          {section.content}
        </section>
      ))}
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
